import React from "react";
import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import FormItem from "../../../molecules/FormItem/FormItem";
import CheckoutHeader from "../../../atoms/CheckoutHeader/CheckoutHeader";
import styled from "styled-components";
import { PRIMARY } from "../../../../vars/vars";
import { countries } from "../../../../vars/countries";
import AutoCountryHandler from "./AutoCountryHandler";
import ZipCodeHandler from "./ZipCodeHandler";
import OrderSummary from "../../OrderSummary/OrderSummary";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";

const FormAddress = () => {
  const { basketId, basket, basketOptions, setAddress, address } =
    useAppContext();
  const history = useHistory();

  const onSubmit = (values) => {
    setAddress(values);
    history.push("/payment");
  };

  const initialValues = {
    firstName: (address && address.firstName) || "",
    lastName: (address && address.lastName) || "",
    companyName: (address && address.company) || "",
    email: (address && address.email) || "",
    emailSecond: (address && address.email) || "",
    phone: (address && address.phone) || "",
    billing: false,
    company: (address && address.company) || "",
    billaddress: (address && address.billaddress) || "",
    billaddress2: (address && address.billaddress2) || "",
    billcity: (address && address.billcity) || "",
    billzip: (address && address.billzip) || "",
    billcountryname: (address && address.billcountryname) || "",
    isCompany: false,
    vat: "",
    streetAddress: (address && address.streetAddress) || "",
    streetAddress2: (address && address.streetAddress2) || "",
    streetAddress3: (address && address.streetAddress3) || "",
    city: (address && address.city) || "",
    zip: (address && address.zip) || "",
    country:
      (address && address.shipcountry) || (address && address.country) || "",
    delivery: (address && address.shippingmethod) || "",
    payment: (address && address.paymentmethod) || "",
    termsConsent: false,
    basketId: basketId,
  };

  const props = {
    onSubmit,
    initialValues,
    validationSchema,
  };

  return (
    <Formik {...props}>
      {(props) => (
        <Form autoComplete={"off"}>
          <AutoCountryHandler />
          <ZipCodeHandler />
          <CheckoutHeader text={"Personal Details"} />
          <FormItem required name={"firstName"} label={"First name"} />
          <FormItem required name={"lastName"} label={"Last name"} />
          <FormItem name={"companyName"} label={"Company (optional)"} />
          <FormItem
            required
            type={"email"}
            name={"email"}
            label={"E-mail"}
            tooltip={"We'll send your order confirmation here."}
          />
          <FormItem
            required
            type={"email"}
            name={"emailSecond"}
            label={"Repeat E-mail"}
          />
          <FormItem
            required
            name={"phone"}
            type={"tel"}
            label={"Phone Number"}
            tooltip={"For delivery questions."}
          />

          <CheckoutHeader text={"Shipping Details"} />
          <FormItem
            required
            name={"streetAddress"}
            label={"Shipping Address"}
            placeholder={"Street Address"}
          />
          <FormItem
            required
            name={"streetAddress2"}
            placeholder={"Street Number"}
          />
          <FormItem
            name={"streetAddress3"}
            placeholder={"Apartment, suite, etc. (optional)"}
          />
          <FormItem required name={"city"} label={"City"} />
          <FormItem required name={"zip"} label={"Zip/Postal Code"} />
          <FormItem
            required
            name={"country"}
            label={"Country/Region"}
            select
            items={countries.filter((country) => !["GB"].includes(country.iso))}
          />

          <FormItem checkbox name={"billing"}>
            Billing details different than Shipping and VAT
          </FormItem>

          {props.values.billing && (
            <div>
              <CheckoutHeader text={"Billing Data"} />
              <FormItem
                required
                name={"company"}
                label={"Company name"}
                placeholder={"Company name"}
              />
              <FormItem
                required
                name={"billaddress"}
                label={"Address and number"}
                placeholder="Street address and number"
              />
              <FormItem
                name={"billaddress2"}
                placeholder={"Apartment, suite etc. (optional)"}
              />
              <FormItem
                required
                name={"billcity"}
                label={"City"}
                placeholder={"City"}
              />
              <FormItem
                required
                name={"billzip"}
                label={"Postal Code"}
                placeholder={"Postal Code"}
              />
              <FormItem
                required
                name={"billcountryname"}
                label={"Country"}
                select
                items={countries.filter(
                  (country) => !["GB"].includes(country.iso)
                )}
              />

              <FormItem checkbox name={"isCompany"}>
                <Terms>
                  I want to purchase as a B2B customer with an active VAT number
                </Terms>
              </FormItem>
              {props.values.isCompany && (
                <FormItem required name={"vat"} label={"VAT Number"}></FormItem>
              )}
            </div>
          )}

          <CheckoutHeader text={"Select shipping method"} />

          {basketOptions &&
          basketOptions.shippingmethod &&
          basketOptions.shippingmethod.length ? (
            <FormItem
              required
              name={"delivery"}
              label={"Delivery Method"}
              select
              items={basketOptions.shippingmethod.map((method) => ({
                iso: method._id,
                name:
                  method.displayname === method.description
                    ? method.displayname
                    : `${method.displayname} - ${method.description}`,
              }))}
            />
          ) : null}
          <CheckoutHeader text={"Select payment method"} />

          {basketOptions &&
          basketOptions.paymentmethod &&
          basketOptions.paymentmethod.length ? (
            <FormItem
              required
              name={"payment"}
              label={"Payment Method"}
              select
              items={basketOptions.paymentmethod.map((method) => ({
                iso: method._id,
                name: method.displayname || method.name,
              }))}
            />
          ) : null}
          <FormItem required checkbox name={"termsConsent"}>
            <Terms>
              Agree to{" "}
              <Link to={"/terms"} className="gtm_btn_termsandconditions">
                Terms
              </Link>
            </Terms>
          </FormItem>
          <OrderWrapper>
            <OrderSummary header={"ORDER SUMMARY"} />
          </OrderWrapper>
          <ButtonWrapper>
            <Button type={"submit"}>Next</Button>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default FormAddress;

const ButtonWrapper = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: flex-end;
`;

const Terms = styled.div`
  &:after {
    content: "*";
    color: ${PRIMARY};
    display: inline-block;
  }
`;

const Button = styled.button`
  margin-top: 50px;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  outline: none;
  border: none;
  background-color: #444;
  cursor: pointer;
  height: 35px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0 20px;
  width: 100%;

  &:hover {
    background-color: ${PRIMARY};
  }

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;
const OrderWrapper = styled.div`
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;
