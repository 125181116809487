import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CatalogueItemCard from "../../molecules/CatalogueItemCard/CatalogueItemCard";
import { groupDescriptionOrder } from "../../../vars/orderLists";
import styles from "./SearchResults.module.scss";

const SearchResults = ({ items, addToCart, buttonText }) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const params = new URL(document.location).searchParams;

  const removeDuplicates = (data) => {
    return data.reduce((prev, curr) => {
      const candidate = prev.find((i) => {
        return i.item.id === curr.item.id;
      });
      if (candidate) {
        return prev;
      }
      return [...prev, curr];
    }, []);
  };

  useEffect(() => {
    if (items) {
      const filtr = items.filter((e) => typeof e.item !== "undefined");
      const filtereditems =
        filtr &&
        removeDuplicates(filtr).sort((a, b) => {
          if (a.item.available && !b.item.available) return -1;
          if (!a.item.available && b.item.available) return 1;
          if (a.item.available && !b.item.manufacturerstock) return -1;
          if (!a.item.available && b.item.manufacturerstock) return 1;
          if (!a.item.available && !b.item.manufacturerstock) return -1;
          return 0;
        });
      setFilteredItems(filtereditems);
    }
  }, [items]);

  const groupBy = function groupItemBy(array, property) {
    var hash = {},
      props = property.split(".");
    for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  };

  useEffect(() => {
    setAllItems(groupBy(filteredItems, "groupdescription"));
  }, [filteredItems]);

  return (
    <>
      {Object.keys(allItems)

        .filter((key) =>
          params.has("groups") ? key === params.get("groups") : true
        )
        .map((obj, i) => {
          const found = groupDescriptionOrder.find(
            (el) => el.group.toLocaleLowerCase() === obj.toLocaleLowerCase()
          );
          return found
            ? { name: obj, index: found.que }
            : { name: obj, index: i * -1 };
        })
        .sort((a, b) => {
          return a.index - b.index;
        })
        .map((key, i) => (
          <div key={key + i}>
            <StyledWrapper>
              <StyledTitle>
                {key.name === "undefined" ? "Results" : key.name}
              </StyledTitle>
            </StyledWrapper>
            <section className={styles.SearchResults}>
              {allItems[key.name].map((parts, p) => (
                <StyledDiv key={`${parts.item.id}${p}`}>
                  <CatalogueItemCard
                    part={parts.item}
                    parameter={parts.parameter}
                    addToCart={addToCart}
                    buttonText={buttonText}
                  />
                </StyledDiv>
              ))}
            </section>
          </div>
        ))}
    </>
  );
};

export default SearchResults;

const StyledTitle = styled.p`
  color: black;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  padding-bottom: 30px;
`;

const StyledWrapper = styled.div`
  width: 95%;
  max-width: 750px;
  margin-top: 20px;
  border-top: #ed3730 solid 4px;
  padding-top: 10px;
`;
