import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import CategoriesSelector from "../../organisms/CategoriesSelector/CategoriesSelector";
import PartFinder from "../../organisms/PartFinder/PartFinder";
import { withRouter } from "react-router-dom";
import SearchResults from "../../organisms/SearchResults/SearchResults";
import Loader from "../../molecules/Loader/Loader";
import NoResults from "../../molecules/NoResults/NoResults";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import styled from "styled-components";
import useBasketActions from "../../../hooks/useBasketActions";
import layoutContext from "../../../context/LayoutContext";

const createCategoryTree = (results) =>
  results.reduce((prev, item) => {
    if (prev[item.categorydescription]) {
      if (prev[item.categorydescription][item.groupdescription]) {
        return {
          ...prev,
          [item.categorydescription]: {
            ...prev[item.categorydescription],
            [item.groupdescription]:
              prev[item.categorydescription][item.groupdescription] + 1,
          },
        };
      } else {
        return {
          ...prev,
          [item.categorydescription]: {
            ...prev[item.categorydescription],
            [item.groupdescription]: 1,
          },
        };
      }
    } else {
      return {
        ...prev,
        [item.categorydescription]: {
          [item.groupdescription]: 1,
        },
      };
    }
  }, {});

const removeDuplicates = (data) =>
  data.reduce((prev, curr) => {
    const candidate = prev.find((i) => i.item.id === curr.item.id);
    if (candidate) {
      return prev;
    }
    return [...prev, curr];
  }, []);

const Catalogue = (props) => {
  const context = useContext(layoutContext);
  const [parts, setParts] = useState([]);
  const [controls, setControls] = useState({
    loader: false,
    noResults: false,
  });
  const { addHandler, buttonText } = useBasketActions();
  const [categoryTree, setCategoryTree] = useState({});
  const params = new URL(document.location).searchParams;

  async function allParts() {
    setParts([]);
    context.setLoader(true);
    setControls((prev) => {
      return { ...prev, loader: true };
    });
    const data = {
      action: "getApplications",
      source: "pedderseu",
      manufacturergroup: "5f9bda55dcdf6b0c04f1878b",
      customer: "guest",
      currency: "eur",
      country: "PL",
      show: !!(params.get("make") && params.get("year") && params.get("model")),
      language: "english",
      make: params.get("make") || "",
      model: params.get("model") || "",
      year: params.get("year") || "",
    };
    await fetch("https://ozparts2.usermd.net/api/external/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setParts(responseJson.results);
        setCategoryTree(createCategoryTree(responseJson.results));
        setControls({
          noResults: !responseJson.results.length,
          loader: false,
        });
        context.setLoader(false);
      });
  }

  async function partsByCategory() {
    setParts([]);
    context.setLoader(true);
    const data = {
      action: "getApplications",
      category: params.get("category") || "",
      source: "pedderseu",
      manufacturergroup: "5f9bda55dcdf6b0c04f1878b",
      customer: "guest",
      currency: "eur",
      country: "PL",
      language: "english",
      show: true,
      make: params.get("make") || "",
      model: params.get("model") || "",
      year: params.get("year") || "",
    };
    await fetch("https://ozparts2.usermd.net/api/external/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const parts = removeDuplicates(responseJson.results);
        setParts(parts);
        setCategoryTree(createCategoryTree(parts));
        setControls({
          noResults: !parts.length,
          loader: false,
        });
        context.setLoader(false);
      });
  }

  useEffect(() => {
    if (params.has("category")) {
      partsByCategory();
    } else {
      allParts();
    }
  }, [props.location.search]);

  return (
    <Container>
      <Layout>
        <CategoriesSelector categoryTree={categoryTree} />
        <div>
          <UnderlinedHeader
            text={
              context.catalogueHeader || params.get("category") || "CATALOGUE"
            }
            bolder
          />
          <PartFinder />
          <Loader show={controls.loader} />
          {controls.noResults ? (
            <NoResults />
          ) : (
            <SearchResults
              items={parts}
              addToCart={addHandler}
              buttonText={buttonText}
            />
          )}
        </div>
      </Layout>
    </Container>
  );
};

export default withRouter(Catalogue);

const Layout = styled.div`
  padding-top: 30px;
  display: grid;
  column-gap: 24px;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 3fr;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
