import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import layoutContext from "../../../context/LayoutContext";
import { PRIMARY } from "../../../vars/vars";
import { useHistory } from "react-router";
import { useAppContext } from "./../../../context/AppContext";

const CookieConsent = () => {
  const ctx = useContext(layoutContext);
  const { initialAction } = useAppContext();
  const history = useHistory();

  function handleAgree() {
    localStorage.setItem("consent", "true");
    ctx.setShowCookie(false);
  }

  function handleDecline() {
    ctx.setShowCookie(false);
  }

  function handleLinkClick() {
    ctx.setShowCookie(false);
    history.push("/cookies");
  }

  useEffect(() => {
    if (!ctx.showCookie) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted",
      });
    }
  }, [ctx.showCookie]);

  if (!initialAction) {
    return null;
  }

  return (
    <Wrapper visible={ctx.showCookie}>
      <Container>
        <Content>
          <Text>
            This site uses cookies and similar technologies to help us to make
            this site work properly and to provide you with a better browsing
            experience. Find out more about how we use cookies and how you can
            change your preferences by reading our{" "}
            <MyLink
              onClick={handleLinkClick}
              aria-label="Link to cookie policy"
            >
              Cookie Policy
            </MyLink>
            .
          </Text>
          <Agree
            onClick={handleAgree}
            aria-label="Agree to cookie policy button"
          >
            Agree
          </Agree>
          <Decline
            onClick={handleDecline}
            aria-label="Decline the cookie policy button"
          >
            Decline
          </Decline>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default CookieConsent;

const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? "block" : "none")};
  padding: 20px 0;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 505;
`;

const Text = styled.div`
  padding: 5px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Button = styled.div`
  padding: 5px;
  text-align: center;
  margin: 0 5px;
  width: 220px;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 10px 0 5px 0;
  }
`;

const Agree = styled(Button)`
  background-color: #b30000;
  color: white;
`;

const Decline = styled(Button)`
  background-color: lightgray;
`;

const MyLink = styled.span`
  color: ${PRIMARY};
  &:hover {
    cursor: pointer;
  }
`;
