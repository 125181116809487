import { useCallback, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { getErrorText } from "../vars/functions";
import {
  deleteLine,
  updateLine,
  updateBasket,
  bulkUpdate,
  createAddress,
  createOrder,
  getOrder,
  addToBasket,
  getBasket,
} from "../axios/api";
import { debounce } from "lodash";
import euCountries from "../vars/euCountries";
import { TAX_OBJ } from "../vars/vars";

const useBasketActions = () => {
  const {
    setBasketId,
    basketId,
    setBasketCount,
    setBasket,
    userCountry,
    setSummary,
    setBasketOptions,
    address: storeAdress,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Add to cart");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");

  const addHandler = (record, quantity = 1) => {
    setButtonText("Adding...");
    addToBasket(record, quantity, basketId, userCountry.code)
      .then(({ data }) => {
        const { cart_id } = data;

        if (!basketId) {
          setBasketId(cart_id);
        }
        return cart_id;
      })
      .then((cart_id) => getBasket(cart_id))
      .then(({ data }) => {
        const {
          shoppingcarts: [{ shoppingcart, options }],
          summary,
        } = data;

        const quantity = shoppingcart.transactionlines
          .filter((obj) => obj.kitcomponent !== true)
          .reduce((prev, curr) => prev + Number(curr.quantity), 0);

        setBasketCount(quantity);
        setBasket(shoppingcart);
        setSummary(summary);
        setBasketOptions(options);
        setShowAlert(true);
        setButtonText("Added");
      })
      .catch((e) => {
        setError(getErrorText(e));
      })
      .finally(() => {
        setTimeout(() => {
          setButtonText("Add to cart");
        }, 1000);
      });
  };

  async function create3CAddress() {
    const {
      firstName,
      lastName,
      companyName,
      streetAddress,
      streetAddress2,
      streetAddress3,
      city,
      zip,
      country,
      phone,
      email,
    } = storeAdress;

    const address = {
      address: `${streetAddress} ${streetAddress2}`,
      address2: streetAddress3,
      addressee: `${firstName} ${lastName}`,
      city,
      zip,
      country,
      phone,
      name: companyName || `${firstName} ${lastName}`,
      email,
    };

    const {
      data: { _id },
    } = await addAddress(address);
    return _id;
  }

  async function create3CBillAddress() {
    const {
      company,
      billaddress,
      billing,
      billaddress2,
      billcity,
      billzip,
      billcountryname,
    } = storeAdress;
    if (billing) {
      const address = {
        address: billaddress,
        address2: billaddress2,
        city: billcity,
        zip: billzip,
        country: billcountryname,
        name: company,
      };

      const {
        data: { _id },
      } = await addAddress(address);
      return _id;
    } else {
      return;
    }
  }

  function setSendTogether() {
    return updateFieldHandler("sendtogether", true);
  }

  function setAddress(addressId) {
    return updateFieldHandler("shippingaddress", addressId);
  }

  function setBillAddress(billaddressId) {
    return updateFieldHandler("billingaddress", billaddressId);
  }

  function setEmail() {
    return updateFieldHandler("shipemail", storeAdress.email);
  }

  function setPaymentMethod(method) {
    return updateFieldHandler("paymentmethod", method);
  }

  async function create3COrder() {
    const {
      data: { _id },
    } = await addOrder();
    return _id;
  }

  async function handleOrder() {
    const addressId = await create3CAddress();
    await setAddress(addressId);
    const billaddressId = await create3CBillAddress();
    if (billaddressId) {
      await setBillAddress(billaddressId);
    }
    await setEmail();
    await setPaymentMethod(storeAdress.payment);
    await setReqPayment();
    await setSendTogether();
    return await create3COrder();
  }

  const addAddress = (address) => {
    return createAddress(address, basketId);
  };

  const deleteHandler = (lineId) => {
    setIsLoading(true);
    deleteLine(lineId, basketId).then(reassignBasket);
  };

  const addOrder = () => {
    return createOrder(basketId);
  };

  const bulkUpdateHandler = (changes) => {
    setIsLoading(true);
    bulkUpdate(basketId, changes).then(reassignBasket);
  };

  const updateFieldHandler = (field, value) => {
    setIsLoading(true);
    return updateBasket(basketId, field, value).then(reassignBasket);
  };

  function setReqPayment() {
    return updateFieldHandler("reqpayment", true);
  }

  const get3COrder = (orderId) => {
    return getOrder(orderId);
  };

  const updateHandler = useCallback(
    debounce((value, id, basketId) => {
      setIsLoading(true);
      updateLine(id, basketId, "quantity", value).then(reassignBasket);
    }, 500),
    []
  );

  const updateCountryWithTax = (country, isCompany) => {
    setIsLoading(true);
    return updateBasket(basketId, "shipcountry", country)
      .then(() => {
        const hasZeroVAT = !euCountries.includes(country) || isCompany;
        return updateBasket(
          basketId,
          "tax",
          hasZeroVAT ? TAX_OBJ.ZERO : TAX_OBJ.EU
        );
      })
      .then(reassignBasket);
  };

  const reassignBasket = ({ data }) => {
    const {
      shoppingcarts: [{ shoppingcart, options }],
      summary,
    } = data;

    const quantity = shoppingcart.transactionlines
      .filter((obj) => obj.kitcomponent !== true)
      .reduce((prev, curr) => prev + Number(curr.quantity), 0);

    setBasket(shoppingcart);
    setBasketCount(quantity);
    setSummary(summary);
    setBasketOptions(options);
    setIsLoading(false);
  };

  return {
    updateBasket,
    updateCountryWithTax,
    addHandler,
    showAlert,
    buttonText,
    updateHandler,
    deleteHandler,
    isLoading,
    updateFieldHandler,
    bulkUpdateHandler,
    addAddress,
    addOrder,
    handleOrder,
    get3COrder,
    error,
    setError,
  };
};

export default useBasketActions;
