import React, { createContext, useContext, useState, useEffect } from "react";
import { initialValues } from "../components/organisms/forms/DistributorForm/initialValues";
import { ribbonCategoriesOrder } from "../vars/orderLists";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  loadGtmScript,
  loadHotJarScript,
  loadLiveAgentScript,
} from "./../scripts/index";

const events = ["mousemove", "touchstart", "touchmove", "click"];

const initData = { initialAction: false };

function customSort(array, order) {
  const orderMap = new Map(order.map((item, index) => [item, index]));
  return array.sort(
    (a, b) =>
      (orderMap.get(a) ?? Number.MAX_SAFE_INTEGER) -
      (orderMap.get(b) ?? Number.MAX_SAFE_INTEGER)
  );
}

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [data, setData] = useState(initData);
  const location = useLocation();

  const loadScripts = () => {
    loadHotJarScript();
    loadLiveAgentScript();
    loadGtmScript();
  };

  const loadData = () => {
    const res = sessionStorage.getItem("data");
    const store = JSON.parse(res);
    setData({ ...data, ...store });
    if (store === null || !store || !store.userCountry) {
      fetch("https://api.ozparts.eu/country")
        .then((response) => response.json())
        .then((response) => {
          setUserCountry(response);
        });
    }
    if (store === null || !store.options || !store.categories) {
      fetch("https://ozparts2.usermd.net/api/external/applications", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "getApplications",
          language: "polish",
          source: "pedderspl",
          manufacturergroup: "5f9bda55dcdf6b0c04f1878b",
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.options?.make) {
            setOptions([...new Set(responseJson.options.make.sort())]);
            const items = [
              ...new Set(
                responseJson.options.categorydescription.map((obj) =>
                  obj === "" ? "OTHER" : obj.toUpperCase()
                )
              ),
            ];
            setCategories(customSort(items, ribbonCategoriesOrder));
          }
        });
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      const onEventTriggered = () => {
        setInitailAction(true);
        loadData();
        loadScripts();
        events.forEach((event) =>
          window.removeEventListener(event, onEventTriggered)
        );
      };

      events.forEach((event) =>
        window.addEventListener(event, onEventTriggered)
      );

      return () => {
        events.forEach((event) =>
          window.removeEventListener(event, onEventTriggered)
        );
      };
    } else {
      loadData();
      loadScripts();
      setInitailAction(true);
    }
  }, []);

  const setAllData = (name, value) => {
    const res = sessionStorage.getItem("data");
    setData({ ...JSON.parse(res), [name]: value });
    sessionStorage.setItem(
      "data",
      JSON.stringify({ ...JSON.parse(res), [name]: value })
    );
  };

  const setBasketId = (id) => setAllData("basketId", id);
  const setBasketCount = (count) => setAllData("basketCount", count);
  const setBasket = (basket) => setAllData("basket", basket);
  const setSummary = (summary) => setAllData("summary", summary);
  const setUserCountry = (country) => setAllData("userCountry", country);
  const setBasketOptions = (options) => setAllData("basketOptions", options);
  const setOptions = (options) => setAllData("options", options);
  const setCategories = (categories) => setAllData("categories", categories);
  const setAddress = (address) => setAllData("address", address);
  const setInitailAction = (initialAction) =>
    setAllData("initialAction", initialAction);

  const reset = () => {
    setAllData("basketCount", 0);
    setAllData("basketId", "");
    setAllData("basket", null);
    setAllData("summary", null);
    setAllData("basketOptions", null);
    setAllData("address", initialValues);
  };

  const value = {
    data,
    ...data,
    setBasket,
    setBasketId,
    setBasketCount,
    setSummary,
    setUserCountry,
    setAddress,
    reset,
    setOptions,
    setCategories,
    setBasketOptions,
    setInitailAction,
  };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
