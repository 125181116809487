import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { deliveryTime, LOC_EU } from "../../../vars/vars";

const isDateDifferenceGreaterThan = (
  deliveryObj,
  days,
  delayDaysInDelivery
) => {
  const currentDate = new Date();
  const deliveryDate = new Date(deliveryObj?.eta || deliveryObj);

  const differenceInMilliseconds = deliveryDate - currentDate;
  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (24 * 60 * 60 * 1000)
  );

  const availableInDays = differenceInDays;
  const availableInDaysWithDelay = availableInDays + delayDaysInDelivery;

  return {
    check: differenceInDays <= days,
    info: `Product in transit - delivery within ${availableInDays}-${availableInDaysWithDelay} days`,
  };
};

const DeliveryInfoDetails = ({ part, quantity }) => {
  const [availability, setAvailability] = useState(null);

  useEffect(() => {
    const transit = {
      productsInTransitInLessTreeWeeks: 0,
      deliveryDateUnderThreeWeeks: "",
      productsInTransitInOverTreeWeeks: 0,
      deliveryDateOverThreeWeeks: "",
    };

    const location = part.available.find(({ location }) => location === LOC_EU);
    const locationQty = location?.quantityavailable || 0;

    if (location?.intransit) {
      const deliveries = part.deliveries[LOC_EU];

      deliveries.forEach((obj) => {
        const deliveryTimeCheck = isDateDifferenceGreaterThan(obj, 20).check;

        if (deliveryTimeCheck) {
          transit.productsInTransitInLessTreeWeeks += obj.quantity;
          transit.deliveryDateUnderThreeWeeks = obj.eta;
        } else {
          transit.productsInTransitInOverTreeWeeks += obj.quantity;
          transit.deliveryDateOverThreeWeeks = obj.eta;
        }
      });
    }

    const manufacturer = part.available.find(
      ({ location }) => location === "manufacturer"
    );

    const manufacturerQty =
      (manufacturer &&
        location?.specialbackorder &&
        manufacturer.quantityavailable) ||
      0;

    setAvailability({ locationQty, manufacturerQty, transit });
  }, [part]);

  const getMessage = () => {
    if (!availability) return null;

    const {
      locationQty,
      manufacturerQty,
      transit: {
        productsInTransitInLessTreeWeeks,
        productsInTransitInOverTreeWeeks,
      },
    } = availability;

    const totalLessThanThreeWeeks =
      locationQty + productsInTransitInLessTreeWeeks;
    const totalLessThanThreeWeeksAndManufacturer =
      totalLessThanThreeWeeks + manufacturerQty;
    const totalAllProducts =
      totalLessThanThreeWeeksAndManufacturer + productsInTransitInOverTreeWeeks;

    if (quantity <= locationQty)
      return (
        <Delivery>
          <i
            className="fa-solid fa-truck fa-sm"
            style={{
              color: "green",
            }}
          ></i>
          <p style={{ margin: "0 0 0 10px" }}>{deliveryTime.IN_STOCK}*</p>
        </Delivery>
      );
    if (quantity <= totalLessThanThreeWeeks)
      return (
        <Delivery>
          <i
            className="fa-solid fa-truck fa-sm"
            style={{
              color: "orange",
            }}
          ></i>
          <p style={{ margin: "0 0 0 10px" }}>{deliveryTime.IN_TRANSIT}</p>
        </Delivery>
      );
    if (
      quantity <= totalLessThanThreeWeeksAndManufacturer &&
      quantity > totalLessThanThreeWeeks
    )
      return (
        <Delivery>
          <i
            className="fa-solid fa-truck fa-sm"
            style={{
              color: "orange",
            }}
          ></i>
          <p style={{ margin: "0 0 0 10px" }}>{deliveryTime.IN_MANUFACTURER}</p>
        </Delivery>
      );
    if (
      quantity <= totalAllProducts &&
      quantity > totalLessThanThreeWeeksAndManufacturer
    )
      return (
        <Delivery>
          <i
            className="fa-solid fa-truck fa-sm"
            style={{
              color: "orange",
            }}
          ></i>
          <p style={{ margin: "0 0 0 10px" }}>
            {
              isDateDifferenceGreaterThan(
                availability.transit.deliveryDateOverThreeWeeks,
                20,
                7
              ).info
            }
          </p>
        </Delivery>
      );

    return null;
  };

  return <div>{getMessage()}</div>;
};

export default DeliveryInfoDetails;

const Delivery = styled.div`
  display: flex;
  font-size: 13px;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
`;
