import axios3c from "./axios3c";
import { LOC_EU, LOCATION, ACTION, CURRENCY, CUSTOMER } from "../vars/vars";
import axios from "./index";

export function createLead(values) {
  const {
    billingAddressZip,
    billingAddressCity,
    company,
    billingAddress1,
    billingAddress2,
    email,
    firstName,
    lastName,
    vat,
    www,
    phone,
    billingAddressCountry,
    ...rest
  } = values;
  let restString = "";
  for (const key in rest) {
    restString += `${key} : ${rest[key]}\n`;
  }
  const data = {
    zip: billingAddressZip,
    city: billingAddressCity,
    name: company,
    displayname: company,
    address: billingAddress1,
    address2: billingAddress2,
    addressee: company,
    email,
    firstname: firstName,
    lastname: lastName,
    taxnumber: vat,
    website: www,
    phone,
    currency: "eur",
    isperson: false,
    comment: restString,
    location: LOCATION.EU,
    country: billingAddressCountry,
  };
  const payload = {
    action: ACTION.CREATE_LEAD,
    currency: CURRENCY,
    location: LOCATION.EU,
    ...data,
  };
  return axios3c.post("/users", payload);
}

export function deleteLine(line_id, cart_id) {
  const data = {
    action: "updateCart",
    cart_id,
    field: "deleted",
    line_id,
    value: true,
  };
  return axios3c.post("/cart", data);
}

export function createOrder(cart_id) {
  const data = {
    action: "addOrder",
    cart_id,
  };
  return axios3c.post("/cart", data);
}

export function createAddress(address, cart_id) {
  const data = {
    action: "updateAddress",
    _id: "",
    ...address,
    cart_id,
  };
  return axios3c.post("/cart", data);
}

export function getOrder(transaction_id) {
  const data = {
    action: "getTransactions",
    transaction_id,
  };

  return axios3c.post("/transactions", data);
}

export function updateLine(line_id, cart_id, field, value) {
  const data = {
    action: "updateCart",
    cart_id,
    field,
    line_id,
    value,
  };
  return axios3c.post("/cart", data);
}

export function setPaymentStatus(transaction_id) {
  const data = {
    action: "setPaymentStatus",
    transaction_id,
    customer: CUSTOMER,
  };

  return axios.post("/cart", data);
}

export function bulkUpdate(cart_id, changes) {
  const data = {
    action: "updateCart",
    cart_id,
    changes,
    refresh: true,
  };
  return axios3c.post("/cart", data);
}

export function updateBasket(cart_id, field, value) {
  const data = {
    action: "updateCart",
    cart_id,
    field,
    value,
  };
  return axios3c.post("/cart", data);
}

export function addToBasket(record, quantity, cart_id, country) {
  let data = {
    action: "addToCart",
    location: LOC_EU,
    quantity,
    record,
    country,
  };
  if (cart_id) {
    data = { ...data, cart_id };
  }
  return axios3c.post("/cart", data);
}

export function getBasket(cart_id) {
  const data = {
    action: "getCarts",
    cart_id,
  };

  return axios3c.post("/cart", data);
}

export function getRelatedItems(id) {
  const data = {
    action: "getRelatedItems",
    record: id,
  };
  return axios3c.post("/item", data);
}

export function getItemData(name, country = "") {
  return Promise.all([
    axios3c.post("/item", {
      action: "getItem",
      record: name,
      country,
    }),
    // axios.get("/part/" + name),
  ]);
}

export function getApplicationsItems(id) {
  const data = {
    action: "getApplications",
    item: id,
    language: "english",
    source: "pedderseu",
  };
  return axios3c.post("/item", data);
}

export function getBulkItems(numbers) {
  return axios.post("/parts", { numbers });
}

export function getItemsData(name, country = "") {
  return axios3c.post("/item", {
    action: "getItem",
    record: name,
    byNumber: true,
    country,
  });
}

export function search(keyword, country = "") {
  return axios3c.post("/search", {
    action: "search",
    keyword,
    country,
  });
}

export function searchAllParts() {
  return axios3c.post("/getApplications", {
    language: "english",
    source: "pedderseu",
    manufacturergroup: "5f9bda55dcdf6b0c04f1878b",
  });
}
