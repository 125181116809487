import React from "react";
import { withRouter } from "react-router";
import Group from "../../molecules/Group/Group";
import Category from "../../molecules/Category/Category";
import NowShoppingBy from "../../molecules/NowShoppingBy/NowShoppingBy";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import styled from "styled-components";

const CategoriesSelector = ({ categoryTree, history }) => {
  const params = new URL(document.location).searchParams;

  const handleReset = () => {
    history.push("/parts");
  };

  const filterActiveCategory = (key) =>
    params.has("category") ? params.get("category") === key : true;

  const tree = Object.keys(categoryTree)
    .filter(filterActiveCategory)
    .map((key) => (
      <Category name={key} key={key}>
        {Object.keys(categoryTree[key]).map((subkey) => (
          <Group
            key={subkey}
            name={subkey}
            quantity={categoryTree[key][subkey]}
          />
        ))}
      </Category>
    ));

  return (
    <div>
      <StyledSection>
        <UnderlinedHeader text={"Shop By"} />
        {params.has("category") && (
          <NowShoppingBy
            category={params.get("category")}
            resetHandler={handleReset}
          />
        )}
        <Options>Shopping Options</Options>
        {tree}
      </StyledSection>
    </div>
  );
};

export default withRouter(CategoriesSelector);

const StyledSection = styled.section`
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
`;

const Options = styled.div`
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
  font-weight: bolder;
  font-size: 12px;
  color: #666666;
`;
