import React, { useState } from "react";

import layoutContext from "./LayoutContext";

const LayoutContextProvider = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [smallNavText, setSmallNavText] = useState("");
  const [catalogueHeader, setCatalogueHeader] = useState("");
  const [showCookie, setShowCookie] = useState(
    localStorage.getItem("consent") !== "true"
  );
  const [loader, setLoader] = useState(false);

  const value = {
    showDrawer,
    toggleDrawer: () => {
      setShowDrawer(!showDrawer);
    },
    showCookie,
    setShowCookie,
    smallNavText,
    setSmallNavText,
    catalogueHeader,
    setCatalogueHeader,
    loader,
    setLoader,
  };

  return (
    <layoutContext.Provider value={value}>{children}</layoutContext.Provider>
  );
};

export default LayoutContextProvider;
